import React from "react"

import AppLayout from "@components/layout/App"

import PDF from "@assets/pdf/ADEO_User_Guide-21Jun2022-v5.pdf"

const Page = props => {
  return (
    <AppLayout>
      <object width="100%" height="100%" data={PDF}></object>
    </AppLayout>
  )
}

export default Page
